import React from 'react';

import {Nav} from 'reactstrap';
import {usePage} from 'contexts/PageContext';

import PrivateNavUser from './PrivateNavUser';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';
import {debounce} from 'lodash';
import {IconFA} from 'components/Icons';
import styles from './styles.module.scss';

function PrivateNav(props) {
  const {dark, isMobile} = props;
  const {setSidebar, isOpenLeftSidebar, sidebar} = usePage();

  const toggleSidebar = debounce(() => {
    setSidebar('left', !isOpenLeftSidebar);
  }, 100);

  return (
    <>
      {!!sidebar && (
        <span
          onClick={toggleSidebar}
          className="d-xl-none p-1 ml-2 text-primary cursor-pointer"
        >
          <IconFA name="bars" />
        </span>
      )}
      <div className={styles['private__nav__search__container']}>
        <PrivateNavSearch isMobile={isMobile} hasSidebar={!!sidebar} />
      </div>

      <Nav className="align-items-lg-center ml-auto flex-row" navbar>
        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
