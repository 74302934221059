import React from 'react';
import urljoin from 'url-join';
import {IAF_MAIN_URL} from 'configs/Environment';
import {Row, Col, Button} from 'reactstrap';
import {IconFA} from 'components/Icons';
import styles from './styles.module.scss';
import cx from 'classnames';

function CookieConsentBanner(props) {
  const {toggleOpen, showBanner, onAcceptCookies} = props;

  return (
    <>
      <div className={styles['cookie-consent-banner__backdrop']} />

      <div
        className={cx(styles['cookie-consent-banner'], {
          [styles['cookie-consent-banner--show']]: showBanner,
          [styles['cookie-consent-banner--unshow']]: !showBanner,
        })}
      >
        <Row className="align-items-center flex-column flex-md-row">
          <Col>
            <p className="font-weight-bold mb-2">This website uses cookies</p>
            <p>
              IAF CertSearch uses cookies to enhance your experience. You can
              change your preference at any time. You can find out more by
              viewing our{' '}
              <a href={urljoin(IAF_MAIN_URL, '/policies/privacy')}>
                Privacy Policies
              </a>
              .
            </p>
            <Button color="white" className="btn-link p-0" onClick={toggleOpen}>
              Manage cookie preference <IconFA name="angle-right" />
            </Button>
          </Col>
          <Col className="col-12 mt-3 d-flex flex-column-reverse col-md-auto mt-md-0 d-md-block">
            <Button
              id="neceessary-cookies-btn"
              className="btn-link"
              color="white"
              onClick={() =>
                onAcceptCookies({
                  neccessary: true,
                })
              }
            >
              Accept necessary cookies only
            </Button>
            <Button
              color="primary"
              id="all-cookies-btn"
              className={cx(styles['cookie-consent-banner__button'])}
              onClick={() =>
                onAcceptCookies({neccessary: true, statistic: true})
              }
            >
              Accept all cookies
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CookieConsentBanner;
